<template>
    <div class="main-page">
        <template v-if="showHeader">
            <section class="page-section mb-3" >
                <div class="container">
                    <div class="grid justify-content-between">
                        <div class="col-12 md:col-6 lg:col-5 " >
                            <div class="" >
                                <div class="flex align-items-center ">
                                    <div>
                                        <div class="text-2xl text-primary font-bold">Podata Details</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </template>
        <section class="page-section " >
            <div class="container">
                <div class="grid ">
                    <div class="col comp-grid" >
                        <div class="">
                            <div >
                                <div class="relative-position" style="min-height:100px">
                                    <template v-if="pageReady">
                                        <div class="grid">
                                            <div class="col-12">
                                                <div class="grid align-items-center">
                                                    <div class="col">
                                                        <div class="text-500 text-sm mb-1">Recid: </div>
                                                        <div class="font-bold">{{ item.recid }}</div>
                                                    </div>
                                                </div>
                                                <hr />
                                                <div class="grid align-items-center">
                                                    <div class="col">
                                                        <div class="text-500 text-sm mb-1">Ponum: </div>
                                                        <div class="font-bold">{{ item.ponum }}</div>
                                                    </div>
                                                </div>
                                                <hr />
                                                <div class="grid align-items-center">
                                                    <div class="col">
                                                        <div class="text-500 text-sm mb-1">Releasenum: </div>
                                                        <div class="font-bold">{{ item.releasenum }}</div>
                                                    </div>
                                                </div>
                                                <hr />
                                                <div class="grid align-items-center">
                                                    <div class="col">
                                                        <div class="text-500 text-sm mb-1">Linenum: </div>
                                                        <div class="font-bold">{{ item.linenum }}</div>
                                                    </div>
                                                </div>
                                                <hr />
                                                <div class="grid align-items-center">
                                                    <div class="col">
                                                        <div class="text-500 text-sm mb-1">Del To: </div>
                                                        <div class="font-bold">{{ item.del_to }}</div>
                                                    </div>
                                                </div>
                                                <hr />
                                                <div class="grid align-items-center">
                                                    <div class="col">
                                                        <div class="text-500 text-sm mb-1">Del To2: </div>
                                                        <div class="font-bold">{{ item.del_to2 }}</div>
                                                    </div>
                                                </div>
                                                <hr />
                                                <div class="grid align-items-center">
                                                    <div class="col">
                                                        <div class="text-500 text-sm mb-1">Del Locid: </div>
                                                        <div class="font-bold">{{ item.del_locid }}</div>
                                                    </div>
                                                </div>
                                                <hr />
                                                <div class="grid align-items-center">
                                                    <div class="col">
                                                        <div class="text-500 text-sm mb-1">Del Loccod: </div>
                                                        <div class="font-bold">{{ item.del_loccod }}</div>
                                                    </div>
                                                </div>
                                                <hr />
                                                <div class="grid align-items-center">
                                                    <div class="col">
                                                        <div class="text-500 text-sm mb-1">Orgid: </div>
                                                        <div class="font-bold">{{ item.orgid }}</div>
                                                    </div>
                                                </div>
                                                <hr />
                                                <div class="grid align-items-center">
                                                    <div class="col">
                                                        <div class="text-500 text-sm mb-1">Org Code: </div>
                                                        <div class="font-bold">{{ item.org_code }}</div>
                                                    </div>
                                                </div>
                                                <hr />
                                                <div class="grid align-items-center">
                                                    <div class="col">
                                                        <div class="text-500 text-sm mb-1">Creatdate: </div>
                                                        <div class="font-bold">{{ item.creatdate }}</div>
                                                    </div>
                                                </div>
                                                <hr />
                                                <div class="grid align-items-center">
                                                    <div class="col">
                                                        <div class="text-500 text-sm mb-1">Vendor: </div>
                                                        <div class="font-bold">{{ item.vendor }}</div>
                                                    </div>
                                                </div>
                                                <hr />
                                                <div class="grid align-items-center">
                                                    <div class="col">
                                                        <div class="text-500 text-sm mb-1">Item No: </div>
                                                        <div class="font-bold">{{ item.item_no }}</div>
                                                    </div>
                                                </div>
                                                <hr />
                                                <div class="grid align-items-center">
                                                    <div class="col">
                                                        <div class="text-500 text-sm mb-1">Line D: </div>
                                                        <div class="font-bold">{{ item.line_d }}</div>
                                                    </div>
                                                </div>
                                                <hr />
                                                <div class="grid align-items-center">
                                                    <div class="col">
                                                        <div class="text-500 text-sm mb-1">Price: </div>
                                                        <div class="font-bold">{{ item.price }}</div>
                                                    </div>
                                                </div>
                                                <hr />
                                                <div class="grid align-items-center">
                                                    <div class="col">
                                                        <div class="text-500 text-sm mb-1">Qty: </div>
                                                        <div class="font-bold">{{ item.qty }}</div>
                                                    </div>
                                                </div>
                                                <hr />
                                                <div class="grid align-items-center">
                                                    <div class="col">
                                                        <div class="text-500 text-sm mb-1">Uom: </div>
                                                        <div class="font-bold">{{ item.uom }}</div>
                                                    </div>
                                                </div>
                                                <hr />
                                                <div class="grid align-items-center">
                                                    <div class="col">
                                                        <div class="text-500 text-sm mb-1">Dater: </div>
                                                        <div class="font-bold">{{ item.dater }}</div>
                                                    </div>
                                                </div>
                                                <hr />
                                                <div class="grid align-items-center">
                                                    <div class="col">
                                                        <div class="text-500 text-sm mb-1">Selopt: </div>
                                                        <div class="font-bold">{{ item.selopt }}</div>
                                                    </div>
                                                </div>
                                                <hr />
                                                <div class="grid align-items-center">
                                                    <div class="col">
                                                        <div class="text-500 text-sm mb-1">Po Line Amount: </div>
                                                        <div class="font-bold">{{ item.po_line_amount }}</div>
                                                    </div>
                                                </div>
                                                <hr />
                                                <div class="grid align-items-center">
                                                    <div class="col">
                                                        <div class="text-500 text-sm mb-1">Logdate: </div>
                                                        <div class="font-bold">
                                                            <Chip v-if="item.logdate" :label="$utils.relativeDate(item.logdate)" v-tooltip="{value: $utils.humanDatetime(item.logdate)}" />
                                                        </div>
                                                    </div>
                                                </div>
                                                <hr />
                                                <div class="flex  justify-btween">
                                                    <SplitButton class="p-button p-button-raised p-button-text p-button-sm" :model="getActionMenuModel(item)">
                                                        <i></i>
                                                    </SplitButton>
                                                </div>
                                            </div>
                                        </div>
                                    </template>
                                    <template v-if="loading">
                                        <div class="p-3 text-center">
                                            <ProgressSpinner style="width:50px;height:50px" />
                                        </div>
                                    </template>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </div>
</template>
<script setup>
	import {   toRefs, onMounted } from 'vue';
	import { utils } from '@/utils';
	import { useApp } from '@/composables/app.js';
	import { useViewPage } from '@/composables/viewpage.js';
	const props = defineProps({
		id: [String, Number],
		primaryKey: {
			type: String,
			default: 'recid',
		},
		pageName: {
			type: String,
			default: 'podata',
		},
		routeName: {
			type: String,
			default: 'podataview',
		},
		apiPath: {
			type: String,
			default: 'podata/view',
		},
		editButton: {
			type: Boolean,
			default: true,
		},
		deleteButton: {
			type: Boolean,
			default: true,
		},
		exportButton: {
			type: Boolean,
			default: true,
		},
		msgBeforeDelete: {
			type: String,
			default: "Record deleted successfully",
		},
		msgAfterDelete: {
			type: String,
			default: "Record deleted successfully",
		},
		showHeader: {
			type: Boolean,
			default: true,
		},
		showFooter: {
			type: Boolean,
			default: true,
		},
		isSubPage: {
			type : Boolean,
			default : false,
		}
	});
	const app = useApp(props);
	const page = useViewPage(props); // page hook
	//page state
	const { 
		item, // current record from store - Object
		loading, // api loading state
		pageReady, // api data loaded successfully
	} = toRefs(page.state);
	//page computed propties
	const {
		apiUrl, // page current api path
		currentRecord, // current page record  - Object
	} = page.computedProps
	//page methods
	const 
	{ 
		load, // load data from api
		deleteItem, // deleted current record
		exportPage, // export page records - args = (exportFormats, apiUrl, pageName)
		moveToNextRecord, // load next record from api
		moveToPreviousRecord // load previous record from api
	} = page.methods
	function getActionMenuModel(data){
		return [
		{
			label: "Edit",
			to: `/podata/edit/${data.recid}`,
			icon: "pi pi-pencil"
		},
		{
			label: "Delete",
			command: (event) => { deleteItem(data.recid) },
			icon: "pi pi-minus-circle"
		}
	]
	}
	onMounted(()=>{ 
		const pageTitle = "Podata Details";
		app.setPageTitle(props.routeName, pageTitle); // set browser page title
		load();
	});
</script>
<style scoped>
</style>
